<template>
    <div class="dF" style="gap: 25px">
        <div class="dF aC">
            <a-select
                v-if="!currentCustomTime"
                v-model="currentTime"
                style="width: 200px"
                placeholder="Select Date"
                class="text-primary"
                :showArrow="true"
                :dropdownMenuStyle="{
                    'text-align': 'center',
                    'overflow-y': 'hidden',
                    'max-height': 'max-content',
                }"
            >
                <a-icon
                    slot="suffixIcon"
                    type="caret-down"
                    class="text-primary"
                />

                <a-select-option
                    v-for="time in currentTimeList"
                    :key="time"
                    :value="time"
                    class="text-primary"
                >
                    {{ time }}
                </a-select-option>
            </a-select>

            <a-range-picker
                v-if="currentCustomTime"
                :open="currentCustomTime"
                @change="currentCustomTimeChange"
                class="text-primary"
                style="width: 200px"
            >
                <template #renderExtraFooter>
                    <a-button @click="currentCustomTime = false"
                        >Close</a-button
                    >
                </template>
            </a-range-picker>
        </div>
        <div
            v-if="!disabledCompareDate"
            class="dF text-primary"
            style="font-size: larger"
        >
            <a-icon type="arrow-right" style="padding-top: 25px" />
            <a-icon type="arrow-left" style="padding-top: 15px" />
        </div>
        <div v-if="!disabledCompareDate" class="dF aC">
            <a-select
                v-if="!compareCustomTime"
                v-model="compareTime"
                style="width: 200px"
                class="text-primary"
                :allowClear="compareTime !== 'Compare to'"
                :dropdownMatchSelectWidth="false"
                :dropdownMenuStyle="{
                    'text-align': 'center',
                    'overflow-y': 'hidden',
                    'max-height': 'max-content',
                }"
            >
                <a-icon
                    slot="suffixIcon"
                    type="caret-down"
                    class="text-primary"
                />
                <a-icon slot="clearIcon" type="close" class="text-primary" />

                <a-select-option
                    v-for="time in compareTimeList"
                    :key="time"
                    :value="time"
                    class="text-primary"
                >
                    {{ time }}
                </a-select-option>
            </a-select>

            <a-range-picker
                v-if="compareCustomTime"
                :open="compareCustomTime"
                @change="compareCustomTimeChange"
                class="text-primary"
                style="width: 200px"
            >
                <template #renderExtraFooter>
                    <a-button @click="compareCustomTime = false"
                        >Close</a-button
                    >
                </template>
            </a-range-picker>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";

export default {
    props: {
        disabledCompareDate: {
            type: Boolean,
            default: false,
        },
		setDefaultAsAllTime: {
			type: Boolean,
			default: false,
		},
    },

    data: () => ({
        currentCustomTime: false,
        compareCustomTime: false,
        currentTimeList: [
            "Today",
            "This Week",
            "This Month",
            "This Quarter",
            "This Year",
            "Yesterday",
            "Last Week",
            "Last Month",
            "last Quarter",
            "All Time",
            "Custom",
        ],
        compareTimeList: [
            "Yesterday",
            "Same Day Last Month",
            "Same Day Last Year",
            "Custom",
        ],
    }),

    computed: {
        ...mapState([
            "currentStartDate",
            "currentEndDate",
            "compareStartDate",
            "compareEndDate",
        ]),

        instance() {
            return this.$store.state.instance;
        },

        instanceCreatedAt() {
            return (
                (this.instance && this.instance.createdAt) ||
                new Date().toISOString()
            );
        },

        currentTime: {
            get: function () {
                return `${moment(+this.currentStartDate).format(
                    "ll"
                )} - ${moment(+this.currentEndDate).format("ll")}`;
            },

            set: function (value) {
                if (value === "Custom") {
                    this.currentCustomTime = true;
                    return;
                }
                this.setTimeStampInDates(value, "current");
                this.emitTimeDetails("current");
            },
        },

        compareTime: {
            get: function () {
                if (this.compareStartDate && this.compareEndDate) {
                    return `${moment(+this.compareStartDate).format(
                        "ll"
                    )} - ${moment(+this.compareEndDate).format("ll")}`;
                }
                return "Compare to";
            },

            set: function (value) {
                if (value === "Custom") {
                    this.compareCustomTime = true;
                    return;
                }
                this.setTimeStampInDates(value, "compare");
                this.emitTimeDetails("compare");
            },
        },
    },

	created() {
		if (this.setDefaultAsAllTime) {
			this.setTimeStampInDates("All Time", "current");
		} else if (this.currentStartDate === 1) {
            this.setCurrentDates({
                startDate: moment(this.instanceCreatedAt)
                    .startOf("day")
                    .format("x"),
                endDate: moment().endOf("day").format("x"),
            });
        }

		this.emitTimeDetails();
	},

    methods: {
        ...mapMutations(["setCurrentDates", "setCompareDates"]),

        emitTimeDetails(timeType = "current") {
            if (timeType === "current") {
                this.$emit("current-times-update", {
                    startDate: +this.currentStartDate,
                    endDate: +this.currentEndDate,
                });
            } else {
                this.$emit("compare-times-update", {
                    startDate: +this.compareStartDate,
                    endDate: +this.compareEndDate,
                });
            }
        },

        currentCustomTimeChange(currentCustomTimes) {
            this.currentCustomTime = false;
            this.setCurrentDates({
                startDate: currentCustomTimes[0].startOf("day").format("x"),
                endDate: currentCustomTimes[1].endOf("day").format("x"),
            });
            this.emitTimeDetails("current");
        },

        compareCustomTimeChange(compareCustomTimes) {
            this.compareCustomTime = false;
            this.setCompareDates({
                startDate: compareCustomTimes[0].startOf("day").format("x"),
                endDate: compareCustomTimes[1].endOf("day").format("x"),
            });
            this.emitTimeDetails("compare");
        },

        setTimeStampInDates(value, timeType = "current") {
            let startDate = null;
            let endDate = null;
            switch (value) {
                case "Today":
                    startDate = moment().startOf("day").format("x");
                    endDate = moment().endOf("day").format("x");
                    break;
                case "This Week":
                    startDate = moment().startOf("isoWeek").format("x");
                    endDate = moment().endOf("isoWeek").format("x");
                    break;
                case "This Month":
                    startDate = moment().startOf("month").format("x");
                    endDate = moment().endOf("month").format("x");
                    break;
                case "This Quarter":
                    startDate = moment().startOf("quarter").format("x");
                    endDate = moment().endOf("quarter").format("x");
                    break;
                case "This Year":
                    startDate = moment().startOf("year").format("x");
                    endDate = moment().endOf("year").format("x");
                    break;
                case "Yesterday":
                    startDate = moment()
                        .subtract(1, "days")
                        .startOf("day")
                        .format("x");
                    endDate = moment()
                        .subtract(1, "days")
                        .endOf("day")
                        .format("x");

                    break;
                case "Last Week":
                    startDate = moment()
                        .subtract(1, "week")
                        .startOf("isoWeek")
                        .format("x");
                    endDate = moment()
                        .subtract(1, "week")
                        .endOf("isoWeek")
                        .format("x");
                    break;
                case "Last Month":
                    startDate = moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("x");
                    endDate = moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("x");
                    break;
                case "last Quarter":
                    startDate = moment()
                        .startOf("quarter")
                        .subtract(1, "quarter")
                        .format("x");
                    endDate = moment()
                        .endOf("quarter")
                        .subtract(1, "quarter")
                        .format("x");
                    break;
                case "All Time":
                    startDate = moment(this.instanceCreatedAt)
                        .startOf("day")
                        .format("x");
                    endDate = moment().endOf("day").format("x");
                    break;
                case "Same Day Last Month":
                    startDate = moment()
                        .subtract(1, "month")
                        .startOf("day")
                        .format("x");
                    endDate = moment()
                        .subtract(1, "month")
                        .endOf("day")
                        .format("x");
                    break;
                case "Same Day Last Year":
                    startDate = moment()
                        .subtract(1, "year")
                        .startOf("day")
                        .format("x");
                    endDate = moment()
                        .subtract(1, "year")
                        .endOf("day")
                        .format("x");
                    break;
            }
            if (timeType === "current") {
                this.setCurrentDates({
                    startDate,
                    endDate,
                });
            } else {
                this.setCompareDates({
                    startDate,
                    endDate,
                });
            }
        },
    },
};
</script>

<style scoped>
</style>
